import React from "react";

const PressureIcon = ({
    size = 24,
    color = "currentColor",
    className = "",
    ...props
}) => {
    return (
        <div className="flex items-center justify-center">
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={className}
                {...props}
            >
                {/* Semicircular gauge */}
                <path d="M4 12a8 8 0 1 1 16 0" />

                {/* Needle */}
                <path d="M12 8v4l3 2" />

                {/* Gauge marks */}
                <path d="M7 10.5l1 0.5" />
                <path d="M17 10.5l-1 0.5" />

                {/* Center point */}
                <circle cx="12" cy="12" r="1" />
            </svg>
        </div>
    );
};

export default PressureIcon;
