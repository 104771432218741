import React, { useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import AssignmentIcon from "@mui/icons-material/Assignment";
import {
    AddSurfSpotButton,
    SurfButton,
    TemperatureButton,
    WindButton,
    PressureButton,
    VisibilityButton,
    SalinityButton,
} from "./Buttons";

import Context from "../context";

export const MainListItems = () => {
    return (
        <div>
            <SurfButton />
            <TemperatureButton />
            <WindButton />
            <PressureButton />
            <VisibilityButton />
            <SalinityButton />
        </div>
    );
};

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Your Data</ListSubheader>

        <AddSurfSpotButton />

        <ListItemButton button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
        </ListItemButton>
        <ListItemButton button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
        </ListItemButton>
    </div>
);
