import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import MapGL, { LngLat, Layer, Source } from "react-map-gl";

import { SurfSpot, Buoy, User, DraggableSurfer } from "../../Icons";
import Context from "../context";
import LocContext from "../LocContext";
import { MapControls, BuoyInfoPopup, SurfSpotInfoPopup, BuoyIndexSlider } from "./MapComponents";
import * as turf from "@turf/turf";

// import BuoyInfo from "./MapComponents/BuoyInfo";
import { borderColor } from "@mui/system";
import API from "../API";

export default function Map() {
    const [popupInfo, setBuoyInfo] = useState(null);
    const [surfSpotInfo, setSurfSpotInfo] = useState(null);
    const [circleSource, setCircleSource] = useState(false);
    const { addSurfSpot, buoyIndex, buoyMarkers, groupLocations, mapMode, setAddSurfSpot, setBuoyIndex, surfSpots, userLoc } = useContext(Context);

    const { loc } = useContext(LocContext);

    const [viewport, setViewport] = useState({
        // latitude: 37.8,
        // longitude: -122.4,
        zoom: 5,
        bearing: 0,
        pitch: 0,
    });

    //triggers when map mode switches
    useEffect(() => {
        setBuoyInfo(null);
    }, [mapMode]);

    // function returnLineData({
    //     loc,
    //     radius = 250,
    //     color = "#088",
    //     opacity = 0.4,
    //     name = "name",
    //     borderColor = "yellow",
    // }) {
    //     const layerStyle = {
    //         id: name,
    //         type: "fill",
    //         paint: {
    //             "fill-color": color,
    //             "fill-opacity": opacity,
    //             "fill-outline-color": borderColor,
    //         },
    //     };

    //     let _center = turf.point([loc.lng, loc.lat]);
    //     let _radius = radius;
    //     let _options = {
    //         steps: 50,
    //         units: "kilometers", // or "mile"
    //     };

    //     let _circle = turf.circle(_center, _radius, _options);
    //     var line = turf.lineString(..._circle.geometry.coordinates);
    //     return { line, layerStyle };
    // }

    useEffect(() => {
        // console.log({ groupLocations });
        //ONLY BUOYS
        // const sources = Object.keys(groupLocations).map((name, i) => {
        //     const loc = groupLocations[name];
        //     if (loc.type !== "buoyGroup") return <></>;
        //     const { line, layerStyle } = returnLineData({
        //         loc,
        //         name,
        //         color: "#f00",
        //         opacity: 0.4,
        //         borderColor: "green",
        //         radius: 2000,
        //     });
        //     return (
        //         <Source key={name} id={name} type="geojson" data={line}>
        //             <Layer {...layerStyle} />
        //         </Source>
        //     );
        // });
        //USE CIRCLE
        // if (loc?.longitude && loc?.latitude && groupLocations) {
        //     const name = "USER_LOC";
        //     const userSettings = groupLocations[name];
        //     const { line, layerStyle } = returnLineData({
        //         loc: { lat: loc.latitude, lng: loc.longitude },
        //         name,
        //         color: "#ff0",
        //         opacity: 0.4,
        //         borderColor: "green",
        //         radius: userSettings.radius,
        //     });
        //     sources.push(
        //         <Source key={name} id={name} type="geojson" data={line}>
        //             <Layer {...layerStyle} />
        //         </Source>
        //     );
        // }
        // setCircleSource(sources);
    }, [loc, groupLocations]);

    //boolean that triggers when setting a surf spot
    useEffect(() => {
        if (!addSurfSpot) return;

        console.log(viewport);

        // setViewport({
        //     ...viewport,
        //     zoom: 14,
        // });
    }, [addSurfSpot]);

    //Updating location of map
    useEffect(() => {
        if (!loc) return;

        setViewport({
            ...viewport,
            latitude: loc.latitude,
            longitude: loc.longitude,
        });
    }, [loc]);

    let filledMarkers = {};

    const Buoys = useMemo(() => {
        const allBuoys = {};
        console.log("make buoys");
        //fill in the gaps

        Object.keys(buoyMarkers).forEach((buoyId, index) => {
            const buoyData = buoyMarkers[buoyId];
            const checkMode = checkMapMode(buoyData, mapMode);
            if (!checkMode) return;

            allBuoys[buoyId] = buoyData;
        });

        filledMarkers = fillGaps(allBuoys, mapMode);

        const BuoyMarkerIcons = Object.keys(filledMarkers).map((buoyId, index) => {
            const buoyData = filledMarkers[buoyId];
            const checkMode = checkMapMode(buoyData, mapMode);
            if (!checkMode) return <React.Fragment key={buoyId}></React.Fragment>;
            return (
                <Buoy
                    key={buoyId}
                    mapMode={mapMode}
                    onClick={async () => {
                        setBuoyInfo(buoyData);
                        const data = await API.getBuoy(buoyId);
                        setBuoyInfo(data[buoyId]);
                    }}
                    buoyData={buoyData}
                    buoyIndex={buoyIndex}
                />
            );
        });

        return BuoyMarkerIcons;
    }, [buoyMarkers, mapMode, buoyIndex]);

    const PopWindow = useMemo(() => {
        return <BuoyInfoPopup setInfo={setBuoyInfo} info={popupInfo} />;
    }, [popupInfo]);

    // console.log({ viewport });

    return (
        <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
            onMove={setViewport}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            onClick={(e) => {
                if (e.lngLat) {
                    console.log({ lat: e.lngLat.lat, lng: e.lngLat.lng });
                }
            }}
        >
            {/* <Source id="my-data" type="geojson" data={_circle}>
                <Layer {...layerStyle} />
            </Source> */}
            {circleSource && circleSource.map((c) => c)}
            <BuoyIndexSlider filledMarkers={filledMarkers} />
            {userLoc && <User width={20} height={20} {...userLoc} />}
            {/* BUOYS */}
            {Buoys}

            {/* ~~~~  Hidden SUrf Spots ~~~ */}
            {/* {surfSpots?.length &&
                surfSpots.map((surfSpot) => {
                    return (
                        <SurfSpot
                            onClick={() => {
                                console.log(surfSpot);
                                setSurfSpotInfo(surfSpot);
                            }}
                            key={surfSpot._id}
                            surfSpot={surfSpot}
                        />
                    );
                })} */}

            {popupInfo && PopWindow}

            {/* ~~~~~  Surf spot popup ~~~~~ */}
            {/* {surfSpotInfo && (
                <SurfSpotInfoPopup
                    setInfo={setSurfSpotInfo}
                    info={surfSpotInfo}
                />
            )} */}
            {/* {addSurfSpot && <DraggableSurfer map={viewport} />} */}

            <MapControls />
        </MapGL>
    );
}

const dataPerMapMode = {
    swell: ["height", "period", "swellDir"],
    wind: ["windDir", "windGust", "windSpeed"],
    temperature: ["airTemp", "waterTemp"],
};
function checkMapMode(buoyData, mapMode) {
    // console.log(buoyData, mapMode);
    const data = buoyData[buoyData.length - 1];
    if (mapMode === "swell") {
        let { height, period, swellDir } = data;
        if (height === null) {
            // console.log("no height");
        } else if (period === null) {
            // console.log("no period");
        } else if (swellDir === null) {
            // console.log("no swellDir");
        } else {
            // console.log("this buoy is good to go");
            return true;
        }
    } else if (mapMode === "wind") {
        const { windDir, windGust, windSpeed } = data;

        if (windDir === null) {
            // console.log("no windDir");
        } else if (windGust === null) {
            // console.log("no windGust");
        } else if (windSpeed === null) {
            // console.log("no windSpeed");
        } else {
            // console.log("this buoy is good to go");
            return true;
        }
    } else if (mapMode === "temperature") {
        const { airTemp, waterTemp } = data;

        if (airTemp === null) {
            // console.log("no airTemp");
        } else if (waterTemp === null) {
            // console.log("no waterTemp");
        } else {
            // console.log("this buoy is good to go");
            return true;
        }
    } else if (mapMode === "pressure") {
        const { pressureTendency, pressure } = data;

        if (pressureTendency === null || pressureTendency === undefined) {
            // console.log("no airTemp");
        } else if (pressure === null || pressure === undefined) {
            // console.log("no waterTemp");
        } else {
            // console.log("this buoy is good to go");
            return true;
        }
    } else if (mapMode === "salinity") {
        const { salinity } = data;

        if (salinity === null || salinity === undefined) {
            // console.log("no airTemp");
        } else {
            // console.log("this buoy is good to go");

            return true;
        }
    } else if (mapMode === "visibility") {
        const { visibility } = data;

        if (visibility === null || visibility === undefined) {
            // console.log("no airTemp");
        } else {
            // console.log("this buoy is good to go");

            return true;
        }
    } else {
        throw new Error("Whats the mapMode@?@?!?");
    }
    return false;
}

function fillGaps(buoyMarkers, mapMode) {
    let longest;
    let timestamps = [];
    //find the longest one?
    Object.keys(buoyMarkers).forEach((buoyId) => {
        const data = buoyMarkers[buoyId];
        if (!longest) {
            longest = data.length;
            timestamps = data.map(({ GMT }) => GMT);
        } else if (data.length > longest) {
            longest = data.length;
            timestamps = data.map(({ GMT }) => GMT);
        }
    });

    // console.log({ longest, timestamps });

    Object.keys(buoyMarkers).forEach((buoyId) => {
        const buoyHistory = buoyMarkers[buoyId];
        // console.log(buoyHistory);
        const finalBuoyData = [];

        // console.log("Starting length ", buoyHistory.length);

        timestamps.forEach((GMT, i) => {
            //does this data history have this timestamp?
            const found = buoyHistory.find((data) => data.GMT === GMT);
            if (!found) {
                // console.log("NO we neeeed this");
                if (i === 0) {
                    return;
                }
                const prevGMT = timestamps[i - 1];
                const prevData = buoyHistory.find((d) => d.GMT === prevGMT);

                if (!prevData) {
                    return;
                }
                finalBuoyData.push({ lerp: true, GMT, ...prevData });
            } else {
                // console.log("yes we have this");
            }
        });

        // console.log("added data ", finalBuoyData.length);
        buoyHistory.push(...finalBuoyData);
        // console.log("Final length ", buoyHistory.length);

        buoyHistory.sort((a, b) => new Date(a.GMT).getTime() - new Date(b.GMT).getTime());

        // const dataKeys = dataPerMapMode[mapMode];

        // dataKeys?.forEach((dataKey) => {
        //     //look for all the data is there
        //     buoyHistory.forEach((data, dataI) => {
        //         //so we have a data?
        //         const currentData = data[dataKey];

        //         if (currentData) {
        //             // console.log(`Got ${currentData} for ${dataKey}`);
        //         } else {
        //             // console.log(`NO ! Got ${currentData} for ${dataKey}`);
        //         }
        //     });
        // });
    });

    return buoyMarkers;
}
