import colors from "./colors";

export function sizePeriod(sec) {
    // console.log("WHHHAT");
    if (sec < 8) return 15;
    else if (sec < 9) return 19;
    else if (sec < 10) return 25;
    else if (sec < 10) return 29;
    else if (sec < 11) return 33;
    else if (sec < 12) return 37;
    else if (sec < 13) return 41;
    else if (sec < 14) return 45;
    else if (sec < 16) return 49;
    else if (sec < 17) return 53;
    else if (sec < 18) return 57;
    else if (sec < 19) return 61;
    else if (sec < 20) return 65;
    else if (sec >= 20) return 69;
    else return 90;
}

export function cardinalToDegrees(cardinalDir) {
    // console.log(cardinalDir);

    if (cardinalDir === "N") {
        return 180;
    } else if (cardinalDir === "NNW") {
        return 159;
    } else if (cardinalDir === "NW") {
        return 136;
    } else if (cardinalDir === "WNW") {
        return 113;
    } else if (cardinalDir === "W") {
        return 90;
    } else if (cardinalDir === "WSW") {
        return 67;
    } else if (cardinalDir === "SW") {
        return 45;
    } else if (cardinalDir === "SSW") {
        return 23;
    } else if (cardinalDir === "S") {
        return 0;
    } else if (cardinalDir === "SSE") {
        return 337;
    } else if (cardinalDir === "SE") {
        return 314;
    } else if (cardinalDir === "ESE") {
        return 292;
    } else if (cardinalDir === "E") {
        return 270;
    } else if (cardinalDir === "ENE") {
        return 248;
    } else if (cardinalDir === "NE") {
        return 220;
    } else if (cardinalDir === "NNE") {
        return 199;
    } else return 0;
}

export function colorFt(height) {
    const ft = Math.floor(height);
    // console.log({ ft, length, height })

    const color = getColor(ft);
    // console.log({ color })
    return color;
}

const getColor = (ft) => {
    if (ft >= colors.length) return colors[colors.length - 1];
    else return colors[ft];
};

export function colorWspd(spd) {
    if (isNaN(spd)) spd = 0;
    let color;
    if (spd > 0 && spd < 6) color = colors[0];
    else if (spd > 0 && spd < 3) color = colors[0];
    else if (spd > 2 && spd < 6) color = colors[1];
    else if (spd > 5 && spd < 8) color = colors[2];
    else if (spd > 7 && spd < 10) color = colors[3];
    else if (spd > 9 && spd < 12) color = colors[4];
    else if (spd > 11 && spd < 14) color = colors[5];
    else if (spd > 13 && spd < 16) color = colors[6];
    else if (spd > 15 && spd < 18) color = colors[7];
    else if (spd > 17 && spd < 20) color = colors[8];
    else if (spd > 19 && spd < 22) color = colors[9];
    else if (spd > 21 && spd < 24) color = colors[10];
    else if (spd > 23 && spd < 26) color = colors[11];
    else if (spd > 25 && spd < 28) color = colors[12];
    else if (spd > 27 && spd < 30) color = colors[13];
    else if (spd > 29) color = colors[14];
    // console.log({ color })
    return color;
}

export function parseDegrees(degrees) {
    let direction;
    if (degrees > 337 || degrees < 23) direction = "N";
    else if (degrees > 22 && degrees < 68) direction = "NE";
    else if (degrees > 67 && degrees < 113) direction = "E";
    else if (degrees > 112 && degrees < 158) direction = "SE";
    else if (degrees > 157 && degrees < 203) direction = "S";
    else if (degrees > 202 && degrees < 248) direction = "SW";
    else if (degrees > 247 && degrees < 293) direction = "W";
    else if (degrees > 292 && degrees < 338) direction = "NW";
    // console.log({ direction, degrees })
    return direction;
}

export function colorSalinity(sal) {
    let color;
    if (sal > 20 && sal <= 22) color = colors[0];
    else if (sal > 22 && sal <= 24) color = colors[1];
    else if (sal > 24 && sal <= 26) color = colors[2];
    else if (sal > 26 && sal <= 28) color = colors[3];
    else if (sal > 28 && sal <= 30) color = colors[4];
    else if (sal > 30 && sal <= 32) color = colors[5];
    else if (sal > 32 && sal <= 34) color = colors[6];
    else if (sal > 34 && sal <= 36) color = colors[7];
    else if (sal > 36 && sal <= 38) color = colors[8];
    else if (sal > 38 && sal <= 40) color = colors[9];
    else if (sal > 40 && sal <= 42) color = colors[10];
    else if (sal > 42 && sal <= 44) color = colors[11];
    else if (sal > 44 && sal <= 46) color = colors[12];
    else if (sal > 46 && sal <= 48) color = colors[13];
    else if (sal > 48 && sal <= 50) color = colors[14];

    return color;
}

export function visibilityColor(vis) {
    let color;
    if (vis > 0 && vis <= 1) color = colors[0];
    else if (vis > 1 && vis <= 2) color = colors[1];
    else if (vis > 2 && vis <= 3) color = colors[2];
    else if (vis > 3 && vis <= 4) color = colors[3];
    else if (vis > 4 && vis <= 5) color = colors[4];
    else if (vis > 5 && vis <= 6) color = colors[5];
    else if (vis > 6 && vis <= 7) color = colors[6];
    else if (vis > 7 && vis <= 8) color = colors[7];
    else if (vis > 8 && vis <= 9) color = colors[8];
    else if (vis > 9 && vis <= 10) color = colors[9];
    else if (vis > 10 && vis <= 11) color = colors[10];
    else if (vis > 11 && vis <= 12) color = colors[11];
    else if (vis > 12 && vis <= 13) color = colors[12];
    else if (vis > 13 && vis <= 14) color = colors[13];
    else if (vis > 14) color = colors[14];

    // console.log({ color })
    return color;
}

export function colorPressureTendency(pressureTendency) {
    let color;
    if (pressureTendency < -0.09) color = colors[0];
    else if (pressureTendency >= -0.09 && pressureTendency < -0.075) color = colors[1];
    else if (pressureTendency >= -0.075 && pressureTendency < -0.06) color = colors[2];
    else if (pressureTendency >= -0.06 && pressureTendency < -0.045) color = colors[3];
    else if (pressureTendency >= -0.045 && pressureTendency < -0.03) color = colors[4];
    else if (pressureTendency >= -0.03 && pressureTendency < -0.015) color = colors[5];
    else if (pressureTendency >= -0.015 && pressureTendency < 0) color = colors[6];
    else if (pressureTendency >= 0 && pressureTendency < 0.015) color = colors[7];
    else if (pressureTendency >= 0.015 && pressureTendency < 0.03) color = colors[8];
    else if (pressureTendency >= 0.03 && pressureTendency < 0.045) color = colors[9];
    else if (pressureTendency >= 0.045 && pressureTendency < 0.06) color = colors[10];
    else if (pressureTendency >= 0.06 && pressureTendency < 0.075) color = colors[11];
    else if (pressureTendency >= 0.075 && pressureTendency < 0.09) color = colors[12];
    else if (pressureTendency >= 0.09) color = colors[13];

    return color;
}

export function colorPressure(pressure) {
    debugger;
    let color;
    if (pressure > 20 && pressure <= 22) color = colors[0];
    else if (pressure > 22 && pressure <= 24) color = colors[1];
    else if (pressure > 24 && pressure <= 26) color = colors[2];
    else if (pressure > 26 && pressure <= 28) color = colors[3];
    else if (pressure > 28 && pressure <= 30) color = colors[4];
    else if (pressure > 30 && pressure <= 32) color = colors[5];
    else if (pressure > 32 && pressure <= 34) color = colors[6];
    else if (pressure > 34 && pressure <= 36) color = colors[7];
    else if (pressure > 36 && pressure <= 38) color = colors[8];
    else if (pressure > 38 && pressure <= 40) color = colors[9];
    else if (pressure > 40 && pressure <= 42) color = colors[10];
    else if (pressure > 42 && pressure <= 44) color = colors[11];
    else if (pressure > 44 && pressure <= 46) color = colors[12];
    else if (pressure > 46 && pressure <= 48) color = colors[13];
    else if (pressure > 48) color = colors[14];
    // console.log({ color })
    return color;
}

export function sizeGust(spd) {
    spd = parseFloat(spd) * 2;
    let size = 25;
    if (isNaN(spd)) {
        return size;
    } else {
        size = size + spd;
        return size;
    }
}

export function colorTemp(temp) {
    if (isNaN(temp)) temp = 0;

    //temp is between 0 50  colors 0-14
    let color = mapNums(temp, 20, 120, 0, 14);
    color = Math.floor(color);
    return colors[color];
}
function mapNums(x, in_min, in_max, out_min, out_max) {
    return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}
