import React from "react";

const SalinityIcon = ({
    size = 24,
    color = "currentColor",
    className = "",
    ...props
}) => {
    return (
        <div className="flex items-center justify-center">
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={className}
                {...props}
            >
                {/* Water droplet shape */}
                <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z" fill="none" />

                {/* Salt crystal shapes inside */}
                <path d="M9 11L11 9" />
                <path d="M13 15L15 13" />
                <path d="M8 14L10 12" />
                <path d="M14 12L12 14" />
            </svg>
        </div>
    );
};

export default SalinityIcon;
