import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { extent } from "d3-array";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from "recharts";

import Context from "../../context";
import { cardinalToDegrees } from "../../../Utils/Convert";
const margin = {
    top: 5,
    right: 30,
    left: 20,
    bottom: 5,
};
const width = 500;
const height = 300;
const innerHeight = height - (margin.top + margin.bottom);
const innerWidth = width - (margin.right + margin.left) - 130;
function BuoyInfo(props) {
    const { mapMode } = useContext(Context);

    const { info } = props;
    // console.log({ mapMode });

    console.log(info);
    const current = info.slice(-1)[0];
    const stationName = current.stationId;

    const [longitude, latitude] = info[0]?.coords?.coordinates;

    const {
        denomination1,
        denomination2,
        dataName1,
        dataName2,
        dataKey1,
        dataKey2,
        data = [],
        strokeColor1,
        strokeColor2,
        yScales1,
        yScales2,
    } = MakeGraphData({ info, mapMode });

    const YAxis1 = (
        <YAxis
            dataKey={dataKey1}
            yAxisId={dataKey1}
            domain={yScales1.dataScale.domain()}
            // range={yScale1.range()}
            type={"number"}
        >
            <Label
                style={{
                    textAnchor: "middle",
                    fontSize: "130%",
                    fill: strokeColor1,
                    opacity: 0.5,
                }}
                angle={270}
                value={dataKey1}
            />
        </YAxis>
    );

    const YAxis2 = (
        <YAxis
            type={"number"}
            domain={yScales2.dataScale.domain()}
            // range={yScale2.range()}
            dataKey={dataKey2}
            yAxisId={dataKey2}
            orientation="right"
        >
            <Label
                style={{
                    textAnchor: "middle",
                    fontSize: "130%",
                    fill: strokeColor2,
                    opacity: 0.5,
                }}
                position="insideLeft"
                offset={40}
                angle={90}
                value={dataKey2}
            />
        </YAxis>
    );

    const current1 = current[dataName1];
    const current2 = current[dataName2];

    const arrows = makeDirectionArrows(data);

    return (
        <div key={stationName}>
            <ChartHeader latitude={latitude} longitude={longitude} stationName={stationName} />
            <LineChart width={width} height={height} data={data} margin={margin}>
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis dataKey="time" />
                {YAxis1}
                <text y={yScales1.labelScale(current1)} x="3" fill={strokeColor1} fontSize="20" fontWeight="bold">
                    {`${current1} ${denomination1}`}
                </text>
                <text y={yScales2.labelScale(current2)} x={width - 85} fill={strokeColor2} fontSize="20" fontWeight="bold">
                    {`${current2} ${denomination2}`}
                </text>
                {[...arrows]}
                {YAxis2}

                <Line
                    dot={false}
                    yAxisId={dataKey1}
                    // type="monotone"
                    dataKey={dataKey1}
                    stroke={strokeColor1}
                    // activeDot={{ r: 8 }}
                />
                <Line
                    dot={false}
                    yAxisId={dataKey2}
                    // type="monotone"
                    dataKey={dataKey2}
                    stroke={strokeColor2}
                />
                <Tooltip
                    // itemStyle={{ color: "red" }}
                    labelStyle={{ color: "#eee", fontSize: "20px" }}
                    itemStyle={{ fontSize: "20px" }}
                    contentStyle={{
                        background: "rgba(51, 51, 51, 0.7)",
                        border: "1px solid #666",
                    }}
                />
                <Legend />
            </LineChart>
        </div>
    );

    // return (

    // );
}

export default React.memo(BuoyInfo);

function ChartHeader({ latitude, longitude, stationName }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span>
                <a target="_blank" href={`https://www.ndbc.noaa.gov/station_page.php?station=${stationName}`}>{`ID: ${stationName}`}</a>
            </span>
            <span>{`LAT: ${latitude}`} </span>
            <span>{`LON: ${longitude}`} </span>
        </div>
    );
}

function CustomizedTick(props) {
    console.log(props);
    return <text>OK</text>;
}

function MakeGraphData({ info, mapMode }) {
    let dataKey2, dataKey1, dataName1, dataName2, strokeColor1, strokeColor2, yScales1, yScales2, denomination1, denomination2, data;

    strokeColor1 = "#8884d8";
    strokeColor2 = "#82ca9d";

    if (mapMode === "swell") {
        // console.log("parse data for swell");
        //we want swell ht and period
        denomination1 = "ft";
        denomination2 = "sec";
        dataKey1 = `height (${denomination1})`;
        dataKey2 = `period (${denomination2})`;
        dataName1 = "height";
        dataName2 = "period";
        const direction = "swellDir";
        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    } else if (mapMode === "wind") {
        // console.log("parse data for wind");

        // console.log("parse data for wind");
        //we want swell ht and period
        denomination1 = "kts";
        denomination2 = "kts";
        dataKey1 = `Speed (${denomination1})`;
        dataKey2 = `Gust (${denomination2})`;
        dataName1 = "windSpeed";
        dataName2 = "windGust";
        const direction = "windDir";

        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    } else if (mapMode === "temperature") {
        // console.log("parse data for temperature");

        //we want airTemp and waterTemp
        denomination1 = "F";
        denomination2 = "F";
        dataKey1 = `Air Temp (${denomination1})`;
        dataKey2 = `WaterTemp (${denomination2})`;
        dataName1 = "airTemp";
        dataName2 = "waterTemp";
        const direction = "airTemp"; //"windDir";

        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    } else if (mapMode === "pressure") {
        // console.log("parse data for temperature");
        debugger;
        //we want airTemp and waterTemp
        denomination1 = "in";
        denomination2 = "in";
        dataKey1 = `Pressure (${denomination1})`;
        dataKey2 = `Pressure Tendency (${denomination2})`;
        dataName1 = "pressure";
        dataName2 = "pressureTendency";
        const direction = "pressure"; //"windDir";

        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    } else if (mapMode === "visibility") {
        // console.log("parse data for temperature");
        debugger;
        //we want airTemp and waterTemp
        denomination1 = "nmi";
        denomination2 = "nmi";
        dataKey1 = `visibility (${denomination1})`;
        dataKey2 = `visibility (${denomination2})`;
        dataName1 = "Visibility";
        dataName2 = "Visibility";
        const direction = "visibility"; //"windDir";

        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    } else if (mapMode === "salinity") {
        // console.log("parse data for temperature");
        debugger;
        //we want airTemp and waterTemp
        denomination1 = "nmi";
        denomination2 = "nmi";
        dataKey1 = `salinity (${denomination1})`;
        dataKey2 = `salinity (${denomination2})`;
        dataName1 = "Salinity";
        dataName2 = "Salinity";
        const direction = "salinity"; //"windDir";

        yScales1 = makeYScale(info, dataName1);
        yScales2 = makeYScale(info, dataName2);
        data = makeData({
            info,
            direction,
            dataName1,
            dataName2,
            dataKey1,
            dataKey2,
        });
    }

    return {
        data,
        dataKey1,
        dataKey2,
        dataName2,
        dataName1,
        strokeColor1,
        strokeColor2,
        yScales1,
        yScales2,
        denomination1,
        denomination2,
    };

    function makeData({ info, direction, dataName1, dataName2, dataKey1, dataKey2 }) {
        data = info.map((d) => {
            let _direction = d[direction];
            if (!_direction) return;
            let data1 = d[dataName1];
            let data2 = d[dataName2];
            const GMT = d.GMT;

            return {
                direction: _direction,
                [dataKey1]: parseFloat(data1).toFixed(2),
                [dataKey2]: parseFloat(data2).toFixed(2),
                time: new Date(GMT).toLocaleString(),
            };
        });

        return data.filter((d) => d);
    }
}

function makeYScale(data = [], key) {
    let [min, max] = extent(data, (d) => parseFloat(d[key]));
    // console.log({ min, max, key, data: data.map((d) => parseFloat(d[key])) });

    const dataScale = scaleLinear()
        .domain([0, Math.ceil(max + max * 0.2)])
        .range([0, height]);
    const labelScale = scaleLinear()
        .domain([Math.ceil(max + max * 0.2), 0])
        .range([0, height - (margin.bottom + margin.top + 25)]);

    return { dataScale, labelScale };
}

function makeDirectionArrows(data, dirKey) {
    // console.log(data);
    if (!data?.length) return [];

    function drawArrow(buoyData, i) {
        if (i % 10) return <></>;
        let startX = 80 + (i / data.length) * innerWidth;
        // console.log({ startX: startX });
        // startX = 200;
        // console.log(startX);
        const scale = 1.5;
        const d = `M ${startX}, 100
		l ${5 * scale}, ${-3.75 * scale}
		l ${0 * scale}, ${2.5 * scale}
		l ${7.5 * scale}, ${0 * scale}
		l ${0 * scale}, ${2.5 * scale}
		l ${-7.5 * scale}, ${0 * scale}
		l ${0 * scale}, ${2.5 * scale} z`;

        const dir = cardinalToDegrees(buoyData.direction);
        // console.log({ dir, buoyData: buoyData.direction });
        return (
            <g transform={`rotate(${dir + 90} ${startX + 5} 100)`}>
                {/*  <g> */}
                {/* <text fill="white" y={100} x={startX}>
                    {i}
                </text> */}
                <path fill="yellow" d={d} />;
            </g>
        );
    }

    return data.map((d, i) => drawArrow(d, i));
}
