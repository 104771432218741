import React from "react";

const VisibilityIcon = ({
    size = 24,
    color = "currentColor",
    className = "",
    ...props
}) => {
    return (
        <div className="flex items-center justify-center">
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={className}
                {...props}
            >
                {/* Eye shape */}
                <path d="M2 12s4-8 10-8 10 8 10 8-4 8-10 8-10-8-10-8z" />

                {/* Pupil */}
                <circle cx="12" cy="12" r="3" />
            </svg>
        </div>
    );
};

export default VisibilityIcon;
