import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PressureIcon from "../../../Icons/PressureIcon";
import ListItemText from "@mui/material/ListItemText";

import Context from "../../context";
const CustomizedListItemButton = styled(ListItemButton)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

const CustomizedIcon = styled(PressureIcon)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

export default function PressureButton() {
    const { mapMode, setMapMode } = useContext(Context);

    const isActive = mapMode === "pressure";
    return (
        <CustomizedListItemButton
            button
            isActive={isActive}
            // divider
            // className={styles.isSetSurfReport}
            onClick={() => setMapMode("pressure")}
        >
            <ListItemIcon>
                <CustomizedIcon isActive={isActive} />
            </ListItemIcon>
            <ListItemText primary={`Pressure`} />
        </CustomizedListItemButton>
    );
}
